import React from "react";
// CSS helpers
import styled from "styled-components";

// Components

// Atoms
import { Title } from "components/atoms/title";
import { Button } from "components/atoms/button";

// Images
import Image from "assets/images/demos/hublot.png";

// Images & Videos
import HomeVideo from "assets/video/hublot.mp4";

import { useState } from "react";

interface HorizontalRowProps {
    mt?: number;
}

const HorizontalRow = styled.hr<HorizontalRowProps>`
    margin-right: 80px;
    margin-left: 80px;
    background: ${({ theme }) => theme.colors.gray["400"]};
    height: 1px;
    border-width: 0;
    margin-top: ${({ mt }) => mt ?? 0}px;
`;

const ContentContainer = styled.div`
    height: 320px;
    margin-right: 80px;
    margin-top: 80px;
    margin-left: 80px;
    display: flex;
`;

const TextContent = styled.div`
    margin-left: 40px;
    width: 100%;
    /* line-height: 200%; */
    font-family: "Post Grotesk Thin";
    font-size: x-large;
`;

const CtaContainer = styled.div`
    margin-top: 40px;
    /* position: absolute; */
    /* bottom: 40px; */
    /* background-color: var(--red); */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 200%;
    color: var(--text-color);
    width: 800px;
`;

const Container = styled.div`
    /* background-color: var(--blue); */
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

interface IframeProps {
    isExperience?: Boolean;
}

const Iframe = styled.iframe<IframeProps>`
    opacity: ${({ isExperience }) => (isExperience === true ? 1 : 0)};
    transition: opacity 500ms ease-in-out;

    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

const Video = styled.video`
    width: 100%;
    border-radius: 4px;
`;

export const ExperienceContainer = () => {
    const [isHolder, setHolder] = useState<boolean | undefined>(false);
    const [isExperience, setExperience] = useState<boolean | undefined>(false);
    const [accessRequested, setAccessRequested] = useState<boolean | undefined>(
        false
    );

    const accessExperience = async () => {
        setAccessRequested(true);

        const launchExperience = () => {
            console.log("launch exp");

            setExperience(true);
        };

        setHolder(true);
        setTimeout(launchExperience, 4000);
    };

    return (
        <Container>
            <Title
                type="h1"
                fontSize="100"
                mobilefontsize="3O"
                mt={80}
                align="center"
            >
                LVMH x SOCIETHY
            </Title>

            <HorizontalRow />
            <ContentContainer>
                <img src={Image} alt="Societhy Experience Image" />
                {/* <Video autoPlay muted loop> */}
                    {/* <source src={HomeVideo} type="video/mp4" /> */}
                {/* </Video> */}
                <TextContent>
                    Welcome to the exclusive LVMH mansion.
                    <br />
                    <br />
                    Societhy team designed this whole private space as a demo,
                    just for you.
                    <br />
                    <br />
                    Enjoy the experience in the heart of the mountains.
                    <br />
                    <br />
                    Bon voyage!
                </TextContent>
            </ContentContainer>
            <HorizontalRow mt={80} />

            {!accessRequested && (
                <Footer>
                    <CtaContainer>
                        <Button type="secondary" onClick={accessExperience}>
                            ACCESS EXPERIENCE
                        </Button>
                    </CtaContainer>
                </Footer>
            )}

            {isHolder && (
                <Footer>
                    <CtaContainer>
                        The Experience is launching soon (waiting time ~1min)
                    </CtaContainer>
                </Footer>
            )}
            {accessRequested && (
                <Iframe
                    src="https://portal.furioos.com/embed/i27SgnKAhvw5GQzS2?whiteLabel=true&hideToolbar=true?autoRun=true"
                    title="The Experience"
                    frameBorder={0}
                    allowFullScreen
                    isExperience={isExperience}
                ></Iframe>
            )}
        </Container>
    );
};
